<template>
  <section class="home">
    <section class="description">
      <div class="content">
        <h1 class="home-title">
          UnGhosted
        </h1>

        <div class="about-ghosting">
          “Ghosting and lack of feedback is my biggest struggle. I hated not
          hearing back after applying, phone screening, or even sometimes
          spending hours of my time to prep for a final interview.”
        </div>

        <AppButton
          :to="{ name: 'SignUp' }"
          width="253"
          height="44"
          dark
          color="var(--button-primary-color)"
          class="join-button"
        >
          Join UnGhosted
        </AppButton>

        <h2 class="platform-text">
          a platform created to support you during your job search
        </h2>
      </div>
    </section>
    <section class="features">
      <div class="content">
        <h3 class="features-title">
          Our Key Features:
        </h3>

        <article class="feature">
          <div class="feature-description">
            <div class="feature-title">
              Dashboard
            </div>
            <div class="feature-text">
              Seamlessly track job applications and interviews, and store your files, contacts, communication
              and offers details all in one place.
            </div>
          </div>
          <img src="/img/dashboard-example.png" alt="dashboard image">
        </article>

        <article class="feature feature-reviews">
          <div class="d-flex flex-wrap">
            <img class="mr-12" src="/img/review-example.png" alt="dashboard image">
            <img src="/img/company-example.png" alt="dashboard image">
          </div>
          <div class="feature-description">
            <div class="feature-title">
              Reviews
            </div>
            <div class="feature-text">
              Read detailed reviews to be aware of companies hiring practices.
              Share your own experiences each step of the way to contribute to transparency of the hiring process.
            </div>
          </div>
        </article>
      </div>

      <!-- TODO section for demo video -->
      <!--      <section class="demo">-->
      <!--        <div class="content">-->
      <!--          <h3 class="demo-title">-->
      <!--            See it in action:-->
      <!--          </h3>-->
      <!--          <div class="demo-placeholder" />-->
      <!--        </div>-->
      <!--      </section>-->
    </section>

    <section class="join">
      <AppButton
        :to="{ name: 'SignUp' }"
        width="253"
        height="44"
        class="join-button"
        dark
        color="var(--button-primary-color)"
      >
        Join UnGhosted
      </AppButton>
    </section>
  </section>
</template>

<script>

export default {
  name: 'Home'
}
</script>
<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.home {
  background-color: var(--background-tertiary-color);
}

.description {
  border-bottom-right-radius: 80% 40%;
  border-bottom-left-radius: 80% 40%;
  padding-bottom: 100px;
  background-color: var(--background-secondary-color);

  @include breakpoint-reverse(small) {
    padding: 24px 12px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.content {
  margin: auto;
  padding-top: 96px;
  width: 100%;
  max-width: 960px;

  @include breakpoint-reverse(small) {
    padding-top: 0;
  }
}

.home-title {
  position: relative;
  z-index: 1;
  display: block;
  font-size: var(--font-size-home-title);
  text-align: center;

  &::before {
    content: "";
    position: absolute;
    left: 145px;
    bottom: 34px;
    z-index: -1;
    width: 70%;
    height: 40px;
    background-color: var(--background-highlight-color);

    @include breakpoint-reverse(small) {
      left: 100px;
      height: 30px;
      bottom: 20px;
    }
  }
}

.about-ghosting {
  position: relative;
  z-index: 1;
  margin: 20px auto 66px;
  border-radius: var(--border-radius-default);
  padding: 20px 50px;
  max-width: 547px;
  font-size: var(--font-size-base-lg);
  line-height: var(--line-height-lg);
  letter-spacing: var(--letter-spacing-base);
  text-align: center;
  background-color: var(--background-about-color);

  @include breakpoint-reverse(small) {
    padding: 12px;
    max-width: 340px;
  }

  &::before {
    content: "";
    position: absolute;
    left: -3px;
    bottom: -12px;
    z-index: -1;
    width: 86.35px;
    height: 64.54px;
    background-color: var(--background-about-color);
    transform:
      matrix(-0.2, -0.41, 1.4, -0.71, 0, 0) rotate(
        35deg
      );
  }
}

.join-button {
  display: block;
  margin: 0 auto 18px;
}

.platform-text {
  font-size: var(--font-size-h1);
  text-align: center;
}

.features {
  padding-top: 52px;

  @include breakpoint-reverse(small) {
    padding: 40px 12px 0;
  }
}

.features-title {
  padding-bottom: 100px;
  font-size: var(--font-size-xxlg);
  text-align: center;

  @include breakpoint-reverse(small) {
    padding-bottom: 50px;
  }
}

.feature {
  display: flex;
  justify-content: space-between;

  @include breakpoint-reverse(small) {
    flex-wrap: wrap;
    gap: 30px;
  }

  &:not(:last-child) {
    padding-bottom: 200px;

    @include breakpoint-reverse(small) {
      padding-bottom: 50px;
    }
  }

  .feature-description {
    margin-top: 80px;
    max-width: 310px;

    @include breakpoint-reverse(small) {
      margin-top: 10px;
    }
  }

  .feature-title {
    margin-bottom: 10px;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-xxlg);
  }

  .feature-text {
    font-size: var(--font-size-base-lg);
    line-height: var(--line-height-md);
  }
}

.feature-reviews {
  @include breakpoint-reverse(small) {
    flex-direction: column-reverse;
  }
}

.demo {
  padding: 40px 0;

  .demo-title {
    margin-bottom: 20px;
    font-size: var(--font-size-xxlg);
  }

  .demo-placeholder {
    border-radius: var(--border-radius-default);
    height: 620px;
    background-color: var(--background-secondary-color);
  }
}

.join {
  padding: 120px 0 250px;

  @include breakpoint-reverse(small) {
    padding: 30px 0 50px;
  }
}

</style>
